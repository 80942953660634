import { useEffect, useState } from "react";
import { DownOutlined, UpOutlined, FolderOutlined } from "@ant-design/icons";

import { Button, Tag } from "antd";

import sharedProjectMavenExample from "./hello_codeyai_maven.json";
import sharedProjectSpringbootExample from "./hello_codeyai_springboot.json";
import { addOrUpdateProject } from "../../fakeApi";
import { useNavigate } from "react-router-dom";

const PublicProjects = ({ SelectedDependencies }) => {
  const [current, setCurrent] = useState(0);
  const [projectsData, setProjectsData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const combinedData = [
      sharedProjectMavenExample,
      sharedProjectSpringbootExample,
    ];
    setProjectsData(combinedData);
  }, []);

  const handleOpenProjectClick = (projectData) => {
    if (projectData.store) {
      addOrUpdateProject(projectData, projectData.tempProject.id);
      navigate(`/codeyai/diagram/${projectData.tempProject.id}`);
    } else if (projectData && !projectData.store) {
      addOrUpdateProject(projectData, projectData.id);
      navigate(`/codeyai/diagram/${projectData.id}`);
    }
  };

  const dependencyFilteredProjects =
    SelectedDependencies.length > 0
      ? projectsData?.filter(
          (project) =>
            project.tempProject?.metaData?.dependencies &&
            project.tempProject.metaData.dependencies.some((dependency) =>
              SelectedDependencies.includes(dependency)
            )
        ) || []
      : projectsData || [];

  return (
    <div className="projects-wrapper">
      {dependencyFilteredProjects?.map((project, index) => {
        return (
          <div
            className=" h-fit flex gap-6 pl-12 pr-10 py-5 border-b-[1px]"
            key={index}
          >
            <div className="flex flex-col items-center gap-3 justify-center h-fit">
              <Button
                className=""
                type="primary"
                shape="circle"
                style={{ minWidth: "26px", width: "26px", height: "26px" }}
                icon={
                  <UpOutlined style={{ color: "white", fontSize: "10px" }} />
                }
                onClick={() => setCurrent(current + 1)}
              />
              <p className=" font-bold text-[13px]">{current}</p>
              <Button
                type="primary"
                shape="circle"
                icon={
                  <DownOutlined style={{ color: "white", fontSize: "10px" }} />
                }
                style={{ minWidth: "26px", width: "26px", height: "26px" }}
                onClick={() =>
                  setCurrent(current === 0 ? current : current - 1)
                }
              />
            </div>
            <div className=" w-full flex flex-col gap-1">
              <div className="flex items-center gap-3">
                <FolderOutlined style={{ fontSize: "20px", color: "red" }} />
                <p
                  className="text-blue-600 cursor-pointer hover:text-blue-400"
                  style={{ fontWeight: "normal", fontSize: 16 }}
                  onClick={() => handleOpenProjectClick(project)}
                >
                  {project.tempProject.metaData.name}
                </p>
              </div>
              <p
                className="text-sm first-letter:capitalize text-gray-600"
                style={{ fontSize: "12px" }}
              >
                {project.tempProject.metaData.description}
              </p>
              {project.tempProject.metaData.dependencies && (
                <div className="flex flex-wrap gap-y-1 mt-2">
                  {project.tempProject.metaData.dependencies.map(
                    (dependency, idx) => (
                      <Tag key={"tag" + idx}>{dependency}</Tag>
                    )
                  )}
                </div>
              )}

              <div className=" flex items-end justify-end h-full">
                <span
                  // href=""
                  className="text-blue-600 pr-1"
                  style={{ fontSize: "13px", textTransform: "capitalize" }}
                >
                  User name
                </span>
                <span className=" font-bold text-sm">1,947</span>
                <span
                  className="text-gray-500 pl-1"
                  style={{ fontSize: "13px" }}
                >
                  published Jun 6, 2024 at 17:50
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PublicProjects;
