import { dependencies } from "../ProjectsInitializeForms/dependencies";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Modal, Tag } from "antd";
import { useState } from "react";

const Filters = ({ SelectedDependencies, setSelectedDependencies }) => {
  const { Search } = Input;
  const [IsModalOpen, setIsModalOpen] = useState(false);
  const [dependenciesOption, setdependenciesOption] = useState(dependencies);

  const handelSearchDependencies = (value: string) => {
    const filteredResults = dependencies.filter((item) => {
      return item?.label?.toLowerCase().startsWith(value?.toLowerCase());
    });

    setdependenciesOption(filteredResults);
  };

  const handleCheckedTags = (checkedValues) => {
    setSelectedDependencies((prev) => {
      const newSelections = checkedValues.filter(
        (value) => !prev.includes(value)
      );

      if (newSelections?.length > 0) {
        return [...prev, ...newSelections];
      } else {
        return checkedValues;
      }
    });
  };
  const handleDeleteTage = (el: string) => {
    setSelectedDependencies((prev) => prev.filter((d) => d !== el));
  };

  return (
    <div className="flex gap-16 pl-12 pr-12 justify-between items-center">
      {/* update */}
      <div className="headLeft flex items-center gap-2 flex-1">
        <Button
          icon={<FilterOutlined className="text-[#3f67ad]" />}
          onClick={() => setIsModalOpen(true)}
          // size="small"
          className="flex items-center gap-1 "
        >
          filter by dependencies
        </Button>
        <span className="flex flex-wrap gap-1">
          {SelectedDependencies?.map((el, index) => {
            return (
              <Tag
                className="text-[11px] border-none bg-slate-200 pl-3 pr-3 py-[4px] m-0"
                key={index}
                closable
                onClose={(e) => {
                  e.preventDefault();
                  handleDeleteTage(el);
                }}
              >
                {el}
              </Tag>
            );
          })}
        </span>

        <Modal
          title="Vertically centered modal dialog"
          centered
          open={IsModalOpen}
          onOk={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
          // style={{top : "-100px", right: "-30%"}}
        >
          <Input
            placeholder="Search dependencies"
            allowClear
            onChange={(e) => handelSearchDependencies(e.target.value.trim())}
            suffix={
              <SearchOutlined
                style={{
                  pointerEvents: "none", // Disable pointer events to make it non-clickable
                }}
              />
            }
          />
          <div className=" h-48 overflow-y-scroll">
            <Checkbox.Group
              className="flex flex-col gap-5 pt-5"
              options={dependenciesOption}
              value={SelectedDependencies}
              onChange={handleCheckedTags}
            />
          </div>
        </Modal>
      </div>
      <Search
        placeholder="input search text"
        // onSearch={onSearch}
        className=" min-w-80 w-2/6"
        enterButton
      />
    </div>
  );
};

export default Filters;
