import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import RootLayout from "../pages/components/RootLayout";
import { ErrorPage } from "../pages/ErrorPage/ErrorPage";
import LoadingPage from "../components/LoadingPage";
import ProjectsMain from "../pages/Projects/components/Main";
import SharedProjects from "../pages/Projects/components/SharedProjects";
import LandingPaage from "../pages/LandingPage";
import ResetPWD from "../pages/ResetPWD";
import RecoverPassword from "../pages/RecoverPWD";

const FlowLazy = lazy(() => import("../pages/DigramCreator"));
const ProjectsLazy = lazy(() => import("../pages/Projects"));
const LoginLazy = lazy(() => import("../pages/Login/Login"));
const RegisterLazy = lazy(() => import("../pages/Register/Register"));
const WizardsPageLazy = lazy(() => import("../pages/Wizards"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <LandingPaage />,
      },
      {
        path: "/wizards/:teamId",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <WizardsPageLazy />
          </Suspense>
        ),
      },

      {
        path: "codeyai/diagram/:projectId",
        element: (
          <Suspense fallback={<LoadingPage />}>
            <FlowLazy />
          </Suspense>
        ),
      },
      {
        path: "codeyai/projects",

        element: (
          <Suspense fallback={<LoadingPage />}>
            <ProjectsLazy />
          </Suspense>
        ),
        children: [
          { index: true, element: <ProjectsMain /> },
          { path: "shared", element: <SharedProjects /> },
        ],
      },
    ],
  },
  {
    path: "login",
    element: (
      <Suspense fallback={<LoadingPage />}>
        <LoginLazy />
      </Suspense>
    ),
  },
  {
    path: "register",
    element: (
      <Suspense fallback={<LoadingPage />}>
        <RegisterLazy />
      </Suspense>
    ),
  },
  {
    path: "reset-password",
    element: <ResetPWD />,
  },
  {
    path: "recover-password",
    element: <RecoverPassword />,
  },
]);
