import { useState } from "react";
import Filters from "./Filters";
import PublicProjects from "./PublicProjects";

const ShareProjects = () => {
  const [SelectedDependencies, setSelectedDependencies] = useState([]);
  return (
    <div className="sharedProjects w-full overflow-y-hidden">
      <div
        className="w-full overflow-y-auto custom-scrollbar pt-5 pb-10 flex flex-col gap-3"
        style={{ height: " calc(100vh - 85px)" }}
      >
        <Filters
          SelectedDependencies={SelectedDependencies}
          setSelectedDependencies={setSelectedDependencies}
        />
        <PublicProjects SelectedDependencies={SelectedDependencies} />
      </div>
    </div>
  );
};

export default ShareProjects;
